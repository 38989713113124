@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scroll-style {
  scrollbar-width: auto;
  scrollbar-color: #000;
}

/* Chrome, Edge, and Safari */
.scroll-style::-webkit-scrollbar {
  width: 10px;
}

.scroll-style::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #333;
  border: 3px solid transparent;
  border-radius: 40px;
}

.scroll-style::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px rgb(22 163 74);
  border: 3px solid transparent;
}

/* MenuIcon.css */
.menu-icon {
  width: 40px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.menu-icon.open .bar:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}

.menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-icon.open .bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Optional: Add animation for the menu itself */
.mobile-menu {
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  .max-width-wrapper {
    @apply w-full px-4 mx-auto md:px-6 lg:px-8 xl:px-20;
  }
  body {
    @apply text-gray-700 bg-background bg-tkzBg;
  }
  .mobile-header {
    @apply flex items-center justify-between w-full px-2 bg-tkzBg gap-x-6 md:px-6;
  }
  .mobile-menu-item {
    @apply text-sm font-medium rounded-full px-3 py-1.5 hover:bg-green-500 hover:text-white border-2 border-gray-700;
  }
  .mobile-menu-heading {
    @apply text-lg font-bold capitalize;
  }
  .upper-header {
    @apply items-center justify-between hidden w-full h-10 px-10 bg-green-600 lg:px-8 xl:px-20 lg:flex;
  }
  .lower-header {
    @apply items-center justify-between hidden w-full h-16 px-10 border-b gap-x-6 lg:px-8 xl:px-20 border-neutral-300 bg-tkzBg lg:flex;
  }
  .li-item {
    @apply flex items-center whitespace-nowrap gap-x-1.5 text-sm font-medium hover:!text-green-600;
  }
  .cat-link {
    @apply flex items-center text-sm !font-normal gap-x-2 text-gray-600 px-2 py-1 rounded hover:bg-neutral-100 hover:text-green-600 w-full;
  }
  .footer-div {
    @apply w-full pt-8 lg:pt-0 lg:w-[40%] flex flex-col justify-center items-center lg:items-start;
  }
  .footer-cat-link {
    @apply flex w-fit items-center text-xs lg:text-sm !font-normal gap-x-2 text-green-50 hover:text-green-200 hover:underline;
  }
  .footer-right-link {
    @apply text-xs text-green-50 lg:text-sm whitespace-nowrap hover:text-green-200 hover:underline;
  }
  .footer-heading {
    @apply text-sm font-bold tracking-widest text-white mb-7 ps-1 lg:text-2xl;
  }
  .main-nav-link {
    @apply px-1 pb-[9px];
  }
  .carousel-item {
    @apply flex justify-center items-center h-[60vh] bg-gray-300;
  }
  .carousel-content {
    @apply text-sm text-white;
  }
  .featuredCat-btn {
    @apply relative px-6 py-4 text-white transition duration-300 ease-in-out bg-green-600 rounded-lg shadow-md hover:bg-green-700;
  }
  .product-img {
    @apply object-cover w-full h-[9rem] border md:h-[12.5rem] lg:h-[8rem] xl:h-[10rem] p-1 md:p-1 lg:p-1 lg:mb-0 rounded-3xl mb-2 shadow product-animate;
  }
  .product-name-price {
    @apply w-full mx-auto text-base text-center space-y-1 md:space-y-1.5 lg:space-y-1 lg:mt-2 mt-1 xl:mt-3 xl:mb-4;
  }
  .product-name {
    @apply px-4 text-xs font-medium capitalize truncate md:text-sm;
  }
  .product-price {
    @apply text-xs font-bold text-green-700 md:text-sm;
  }
  .product-animate {
    @apply transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-125;
  }
  .section-heading {
    @apply text-sm font-semibold text-center md:text-sm lg:text-base;
  }
  .section-heading-2 {
    @apply text-lg font-bold text-center md:text-xl lg:text-2xl;
  }
  .read-more-btn {
    @apply flex items-center px-2 py-1 mt-6 text-xs font-medium text-green-600 capitalize border rounded w-fit hover:text-green-700;
  }
  .form-heading {
    @apply text-base font-semibold text-gray-800 lg:text-2xl;
  }
  .all-small-txt {
    @apply text-xs font-medium;
  }
  .desc-txt {
    @apply mt-3 text-xs text-justify md:text-sm md:mt-4;
  }
  .slideBtn {
    @apply absolute top-1/2 p-2.5 md:p-3.5 lg:p-4 bg-white/[0.6] text-black/[0.3] rounded-full border-[1.5px] transform -translate-y-1/2 transition-opacity duration-300 hover:text-black/[0.7] hover:bg-white/[0.7];
  }
  .ctaBtn {
    @apply flex items-center px-4 py-2 text-xs font-semibold text-gray-700 uppercase border rounded w-fit;
  }
  .plus-minus-btn {
    @apply p-0.5 hover:bg-green-600 hover:text-white focus:ring-4 focus:outline-0 focus:ring-green-300 font-medium rounded-full text-sm text-center;
  }
  .checkout-btn {
    @apply flex items-center justify-center w-full px-5 py-3 text-xs font-medium text-center text-white bg-green-600 rounded-full whitespace-nowrap gap-x-4 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 lg:text-sm lg:py-2 hover:cursor-pointer;
  }
  .outline-btn {
    @apply flex items-center justify-center w-full px-5 py-3 text-xs font-medium text-center text-red-500 border-2 border-red-500 rounded-full whitespace-nowrap hover:bg-red-50 focus:outline-none focus:ring-4 focus:ring-red-300 lg:text-sm lg:py-2;
  }
  .svg-transition {
    @apply transition-transform transform group-hover:translate-x-1;
  }
  .dialogue-btn {
    @apply whitespace-nowrap text-center text-gray-700 hover:bg-green-200 px-10 py-1.5 border-[1.5px] border-gray-700 absolute right-28 text-sm font-medium rounded-full focus:outline-none focus:ring-4 focus:ring-green-300;
  }
  .radio-btn {
    @apply w-4 h-4 text-green-500 bg-green-600 border-2 border-gray-800 focus:ring-green-500;
  }
  .breadcrumbTxt {
    @apply text-xs text-gray-600 capitalize ms-1 md:ms-2;
  }
  .cart-summary-li {
    @apply flex items-center justify-between text-sm font-semibold gap-x-1;
  }
  .cart-value {
    @apply text-sm font-medium text-gray-900;
  }
  .type-chip {
    @apply flex w-full justify-center !text-center px-2 py-[2.5px] font-medium !rounded-full !text-xs text-gray-800 border-[1.5px] !border-neutral-400 hover:bg-yellow-200;
  }
  .command-group {
    @apply grid grid-cols-2 gap-x-2 gap-y-3;
  }
  .input-label {
    @apply mb-1 text-xs font-medium text-gray-800 ps-1;
  }
  .input-box {
    @apply border-b-[1.5px] border-gray-900 focus-within:border-yellow-500 p-2 md:py-2 px-1 w-full text-sm font-medium !bg-transparent text-gray-900 focus:!outline-0 placeholder:text-gray-500 placeholder:font-normal;
  }
  .textarea-box {
    @apply border-[1.5px] border-gray-900 p-2 px-4 w-full text-sm font-medium !bg-transparent text-gray-900 rounded-2xl focus:!outline-0 placeholder:text-gray-500 placeholder:font-normal;
  }
  .order-page-header-wrapper {
    @apply flex justify-between py-2 mb-6 lg:mb-4;
  }
  .order-link {
    @apply flex items-center py-2 group-hover:text-white;
  }
  .order-svg {
    @apply group-hover:bg-green-500 px-5 pe-4 py-2.5 bg-white rounded-r-full;
  }
  .order-span {
    @apply flex-1 ms-5 whitespace-nowrap;
  }
  .order-heading {
    @apply !text-black !font-semibold !py-3 !mb-12 whitespace-nowrap;
  }
  .about-us-width {
    @apply flex md:gap-x-14 xl:gap-x-20 py-7 lg:pt-14;
  }
  .about-us-img {
    @apply object-cover h-full rounded-3xl lg:rounded-lg xl:rounded-xl;
  }
  .about-us-img-wrapper {
    @apply w-full md:w-[50%] lg:w-[60%] xl:w-[40%] lg:relative lg:top-12 xl:top-16;
  }
  .isActive {
    @apply border-gray-600;
  }
  .invent-h-4 {
    @apply text-sm font-semibold;
  }
  .inventory-header-btn {
    @apply whitespace-nowrap inline-flex text-sm font-medium px-4 py-1.5 border-2 border-gray-900 rounded-full;
  }
  .inventory-header-icon-btn {
    @apply whitespace-nowrap px-4 py-1.5 border-2 border-gray-900 rounded-full hover:bg-green-200;
  }
  .tabBtn {
    @apply;
  }
  .slider-btn {
    @apply hidden p-1 text-gray-400 rounded-full bg-neutral-200 hover:text-gray-600 hover:bg-neutral-300 md:flex;
  }
  .text-btn {
    @apply flex items-center p-1 text-xs font-medium text-green-600 border rounded-full gap-x-1 hover:border-green-500 hover:bg-green-50 ps-2;
  }
  .td {
    @apply bg-white;
  }
  .category-btn {
    @apply w-fit text-xs font-medium text-yellow-900 px-2 py-[2px] bg-yellow-300 rounded-full;
  }
  .product-label {
    @apply w-full text-sm font-medium;
  }
  .product-input {
    @apply w-full text-sm placeholder:text-xs font-normal focus-visible:!outline-0 focus-visible:!border-gray-900 border-[2px] border-gray-700 px-3 py-2 text-gray-800 placeholder:text-gray-400 rounded-full mt-2;
  }
  .outline-btn {
    @apply !w-fit border-[2px] !border-gray-700 text-gray-800 !bg-transparent hover:!bg-green-100;
  }
  .product-grid {
    @apply grid w-full grid-cols-2 mb-24 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-4 lg:gap-x-8 xl:gap-x-4 gap-y-12 xl:gap-y-14;
  }
  .categorySliderWrapper {
    @apply grid w-full grid-cols-4 grid-rows-2 px-2 py-4 overflow-hidden bg-white rounded-lg shadow gap-y-6 md:grid-cols-5 md:py-8 md:shadow xl:px-6 xl:gap-x-12 md:gap-y-8;
  }
  .authPageWrapper {
    @apply flex justify-between w-full py-10 text-gray-900;
  }
  .activeTab {
    @apply text-green-600;
  }
  .out-of-stock {
    @apply text-xs font-medium text-gray-500 md:text-sm;
  }
}
